























































































































































































































































































































































































































































































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DestinyCard from "../destinyNewCard/DestinyNewCard.vue";
import anime from "animejs";
import DestinyButton from "../destinyButton/DestinyButton.vue";
import {
  getChance,
  getCharacter,
  getCharacterColor,
  getNow,
  countdown,
} from "@/utils/common.util";
import { cloneDeep } from "lodash";
import SupplyPackageApi from "@/api/supply-package.api";
import type { IOrderStatusDetail, ISupplyBox } from "@/api/supply-package.api";
import InteractionLib from "@/utils/interaction.lib";
import { MineApi } from "@/api/mine.api";
import { getModule } from "vuex-module-decorators";
import GlobalStateStore from "@/store/modules/global-state.store";

interface playOptions {
  items: Array<ISupplyBox>;
  test?: boolean;
  detail?: IOrderStatusDetail;
  option?: boolean;
  hiddenTitle?: boolean;
  payType?: boolean;
  preview?: boolean;
  onePrice?: number;
  tenPrice?: number;
  spc?: boolean;
  recycle_status?: boolean;
  box_id?: any;
  freeStatus?: boolean;
  ensure?: { character: number; num: number; count: number };
  upgradeList?: any;
  couponFlag?: boolean;
}

@Component({
  components: { DestinyButton, DestinyCard },
})
export default class DestinyReward extends Vue {
  @Prop({ default: false }) member!: boolean;
  @Prop({ default: 10 }) private readonly drawTotalCount!: number;
  onePrice: number = 0;
  tenPrice: number = 0;
  ensure: any = {};
  cardWideHigh = 120;
  payType = false;

  show = false;
  visible = false;
  test = true;
  option = true;
  preview = false;
  hiddenTitle = false;
  items: Array<ISupplyBox> = [];
  orderStatusDetail: IOrderStatusDetail | undefined = undefined;
  backgroundStyle = {
    background: "white",
  };
  box_id: any;
  cupponTimer: any = "";
  couponFlag: any = false;
  toCombine() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/synthetic`);
    }
    this.$router.push({ name: "Synthetic" });
  }
  globalState = getModule(GlobalStateStore);
  get statusBar() {
    return this.globalState.statusBarHeight;
  }
  toSomeWhere(index: number) {
    if (this.spc) return;
    document.documentElement.style.overflowY = "scroll";
    if (this.preview && this.items[index].type === 2) return;
    let discount = this.items[index];
    if (discount?.coupon?.type == 0) {
      if (discount.coupon?.cap_store) {
        let id = discount.coupon?.cap_store.id;
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/sp-store/${id}?show=true`
          );
        }
        this.$router.push({ path: `/sp-store/${id}?show=true` });
      }
    } else if (discount.coupon?.type == 1) {
      if (discount.coupon?.blind_box) {
        let id = discount.coupon?.blind_box.id;
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/blind-box/${id}`
          );
        }
        window.location.href = `${
          location.origin
        }/blind-box/${id}?time=${getNow()}`;
      }
    } else if (discount.type == 0) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${location.origin}/sp-store/${
            discount.product_id ? discount.product_id : discount.id
          }?show=true`
        );
      }
      this.$router.push({
        path: `/sp-store/${
          discount.product_id ? discount.product_id : discount.id
        }?show=true`,
      });
    } else {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/my-supply`);
      }
      this.$router.push({ name: "MySupply" });
    }
  }

  tocuppon() {
    document.documentElement.style.overflowY = "scroll";
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/my-discount`);
    }
    this.$router.push({ name: "MyDiscount" });
  }
  async created() {
  }
 
  tosup() {
    document.documentElement.style.overflowY = "scroll";
    if (this.items[0].coupon) {
      if (this.items[0].coupon?.type == 0) {
        if (this.items[0].coupon?.cap_store) {
          let id = this.items[0].coupon?.cap_store.id;
          if (InteractionLib.isApp()) {
            return InteractionLib.openWebView(
              `${location.origin}/sp-store/${id}?show=true`
            );
          }
          this.$router.push({ path: `/sp-store/${id}?show=true` });
        }
      } else {
        if (this.items[0].coupon?.blind_box) {
          let id = this.items[0].coupon?.blind_box.id;
          if (InteractionLib.isApp()) {
            return InteractionLib.openWebView(
              `${location.origin}/blind-box/${id}`
            );
          }
          window.location.href = `${
            location.origin
          }/blind-box/${id}?time=${getNow()}`;
        }
      }
    }
  }
  get posStyleFlag() {
    const one = this.isOne; // 单抽
    const is_coupon = !!this.cupponData;
    const ensure =
      this.orderStatusDetail && this.orderStatusDetail.ensure_num > 0; // 有保底
    if (one && is_coupon && ensure) return false; // 不变
    if (one && is_coupon) return true; // 变
    if (one && !is_coupon && ensure) return false; // 不变
    if (!one && is_coupon) return true; // 变
    return false;
  }
  get shineStyle() {
    if (!this.upgradeList) return {};
    if (this.upgradeList.need_num - this.upgradeList.mine_num > 0) {
      return false;
    } else {
      return true;
    }
  }
  get characterText() {
    if (!this.orderStatusDetail) return { text: "", icon: "" };
    const { ensure_character: character } = this.orderStatusDetail;
    const text = getCharacter(character, "", true);
    const icon = character < 5 ? "" : `__character_peak_${character - 4}_icon`;
    return { text, icon };
  }
  get currentCharacter() {
    const text = getCharacter(this.upgradeList.character, "", true);
    const icon =
      this.upgradeList.character < 5
        ? ""
        : `__character_peak_${this.upgradeList.character - 4}_icon`;
    return { text, icon };
  }
  get currentPrizeCharacter() {
    const text = getCharacter(this.upgradeList.prize.character, "", true);
    const icon =
      this.upgradeList.prize.character < 5
        ? ""
        : `__character_peak_${this.upgradeList.prize.character - 4}_icon`;
    return { text, icon };
  }
  get characterStyle(): Record<string, string> {
    if (!this.orderStatusDetail) return {};
    const { ensure_character } = this.orderStatusDetail;
    return getCharacterColor(ensure_character);
  }
  get possibleStyle() {
    if (!this.upgradeList) return {};
    return {
      background: `var(--card-color-${this.upgradeList.character})`,
    };
  }
  get posHighStyle() {
    if (!this.upgradeList) return {};
    return {
      background: `var(--card-color-${this.upgradeList.prize.character})`,
    };
  }
  get isOne(): boolean {
    return this.items.length === 1;
  }

  @Emit()
  testClick(): void {
    this.handleClose();
  }

  checkReward(): void {
    document.documentElement.style.overflowY = "scroll";
    const len = this.items.filter((row) => row.level === -1).length;
    if (len === this.items.length) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(`${location.origin}/my-discount`);
      }
      this.$router.push({
        name: "MyDiscount",
        query: { time: String(getNow()) },
      });
      return;
    }
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(`${location.origin}/mysupply`);
    }
    this.$router.push({ name: "mysupply", query: { time: String(getNow()) } });
  }

  @Emit()
  drawCard(num: number): number {
    this.handleClose();
    return num;
  }

  get character(): number {
    const { character = -1 } = this.oneCardInfo || {};
    return character;
  }

  get cardInfo(): {
    cover: string;
    character: number;
    rate: string;
    product_bazaar_price: number;
    product_price: number;
    price: number;
    id: number;
    title: string;
    type: number;
    duration?: string;
    key: number;
  }[] {
    return this.items.map((row, i) => ({
      key: i,
      type: row.type,
      product_price: row.product_price,
      product_bazaar_price: row.product_bazaar_price,
      id: row.id,
      cover: row.cover,
      character: row.level,
      title: row.title,
      duration: row.duration,
      price: row.price / 100,
      rate: `爆率 ${getChance(row.chance)}%`,
    }));
  }

  toShopDetail() {
    document.documentElement.style.overflowY = "scroll";
    let list = this.oneCardInfo;
    if (!list) return;
    if (list?.coupon?.type == 0) {
      if (list.coupon?.cap_store) {
        let id = list.coupon?.cap_store.id;
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/sp-store/${id}?show=true`
          );
        }
        this.$router.push({ path: `/sp-store/${id}?show=true` });
      }
    } else if (list.coupon?.type == 1) {
      if (list.coupon?.blind_box) {
        let id = list.coupon?.blind_box.id;
        if (InteractionLib.isApp()) {
          return InteractionLib.openWebView(
            `${location.origin}/blind-box/${id}`
          );
        }
        window.location.href = `${
          location.origin
        }/blind-box/${id}?time=${getNow()}`;
      }
    }
    if (!list?.coupon) {
      if (InteractionLib.isApp()) {
        return InteractionLib.openWebView(
          `${location.origin}/sp-store/${list.product_id}?show=true`
        );
      }
      this.$router.push({ path: `/sp-store/${list.product_id}?show=true` });
    }
  }

  get oneCardInfo():
    | {
        coupon: any;
        product_id: number;
        cover: string;
        character: number;
        rate: string;
        price: number;
        id: number;
        title: string;
        type: number;
        product_bazaar_price: number;
        product_price: number;
      }
    | false {
    const row = this.items.find(Boolean);
    if (!row) return false;
    return {
      coupon: row.coupon,
      id: row.id,
      type: row.type,
      product_id: row.product_id,
      cover: row.cover,
      character: row.level,
      product_price: row.product_price,
      product_bazaar_price: row.product_bazaar_price,
      title: row.title,
      price: row.price / 100,
      rate: `爆率 ${getChance(row.chance)}%`,
    };
  }

  mounted(): void {
    this.handleCardWidth();
  }

  handleCardWidth(more = false): void {
    const { offsetWidth, clientWidth } = document.body;
    const width = offsetWidth || clientWidth;
    if (!more) {
      this.cardWideHigh = width / 3;
      return;
    }
    this.cardWideHigh = width / 4;
  }
  spc = false;
  upgradeList: any = "";
  freeStatus = false;
  recycle_status = false
  async playAnimation(opt: playOptions): Promise<void> {
    const {
      items,
      test = true,
      option = true,
      detail = undefined,
      freeStatus = false,
      hiddenTitle = false,
      payType = false,
      preview = false,
      onePrice = 0,
      tenPrice = 0,
      spc = false,
      ensure,
      recycle_status = false,
      upgradeList,
      box_id,
      couponFlag = false,
    } = opt;
    this.couponFlag = couponFlag;
    this.recycle_status = recycle_status;
    this.box_id = box_id;
    this.ensure = ensure;
    this.upgradeList = upgradeList;
    this.spc = spc;
    this.freeStatus = freeStatus;
    this.onePrice = onePrice;
    this.tenPrice = tenPrice;
    this.preview = preview;
    this.items = items;
    this.show = false;
    this.payType = payType;
    this.visible = true;
    this.test = test;
    this.option = option;
    this.hiddenTitle = hiddenTitle;
    this.orderStatusDetail = cloneDeep(detail);
    await this.$nextTick();
    this.show = true;
    await this.$nextTick();
    const num = items.length;
    this.handleCardWidth(num > 1);
    await this.$nextTick();
    this.getCupponData(couponFlag);
    if (!hiddenTitle) return this.play(num);
    this.playNotWhite(num);
  }
  cupponDataFlag: any = "";
  async getCupponData(couponFlag: boolean) {
    this.cupponDataFlag = "";
    this.cupponData = "";
    if (couponFlag) {
      const result = await MineApi.getMyCoupons(1, this.box_id)
        .then((ret) => ret.find(Boolean))
        .catch((err) => console.log(err));
      this.cupponDataFlag = result;
      if (!result) return;
      this.cupponData = result;
      setInterval(() => {
        const { hour, minute, second } = countdown(
          this.cupponData.failure_time
        );
        this.cupponTimer = `${hour}:${minute}:${second}`;
      });
    }
  }
  cupponData: any = "";
  private play(num = 1) {
    let i = 0;
    anime({
      targets: ".__destiny_reward .__mask",
      opacity: [1, 1, 1, 0],
      display: ["", "none"],
      easing: "easeOutElastic(1, .8)",
      duration: 1500,
      update: (anim) => {
        if (anim.currentTime > 1000 && i === 0) {
          i++;
          this.playNotWhite(num);
        }
      },
    });
  }

  private playNotWhite(num = 1): void {
    anime({
      targets: ".__destiny_reward",
      opacity: [0, 1],
      easing: "easeOutElastic(1, .8)",
    });
    anime({
      targets: ".__destiny_reward .__destiny_reward_body",
      opacity: [0, 1],
      easing: "easeOutElastic(1, .8)",
      duration: 300,
    });
    anime({
      targets: ".__destiny_reward .__destiny_card_box",
      opacity: [0, 1],
      scale: [0, num > 1 ? 0.9 : 1.5],
      duration: 800,
    });
  }

  async handleClose(): Promise<void> {
    document.documentElement.style.overflowY = "scroll";
    this.show = false;
    this.visible = false;
  }
}
